<template>
    <div class="form-group">
        <div class="row">
            <div class="col" v-for="option in options">
                <input type="radio"
                       v-model="form[name]"
                       :id="name"
                       :disabled="readonly"
                       :value="option.id">
                <label class="form-check-label">{{ option.name }}</label>
            </div>
            <div class="col">
                <button class="btn btn-sm" v-if="!readonly" @click.prevent="resetRadio" style="padding: 0!important;">
                    <i class="fas fa-remove"></i> <!-- Font Awesome undo icon -->
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import sharedState from "../sharedState"

export default {
    name: "AndOrInput",
    props: {
        name: String,
        form: Object,
        readonly: {type: Boolean, default: false},
    },
    data() {
        return {
            options: [{"id": 1, "name": "and"}, {"id": 2, "name": "or"}]
        }
    },
    methods: {
        resetRadio() {
            this.form[this.name] = null
        }
    },
}
</script>

<style scoped>

</style>
