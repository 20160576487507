<template>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <form class="form-horizontal">
                        <div class="row">
                            <div class="col-12 col-xl-6">
                                <div class="row">
                                    <div class="col">
                                        <text-input :form="form"
                                                    :label="'Last name'"
                                                    name="last_name"
                                                    :readonly="readonly"/>
                                    </div>
                                    <div class="col">
                                        <text-input :form="form"
                                                    :label="'First name'"
                                                    name="first_name"
                                                    :readonly="readonly"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <text-input :form="form"
                                                    :label="'Full name'"
                                                    name="full_name"
                                                    :readonly="readonly"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <multi-select-input :form="form"
                                                            :label="'Categories'"
                                                            name="categories"
                                                            :options="$categories"
                                                            :readonly="readonly"
                                        />
                                    </div>
                                    <div class="col-4" style="padding-top:30px">
                                        <and-or-input :form="form"
                                                     name="categories_and_or"
                                                     :readonly="readonly"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <text-area-input :form="form"
                                                         :label="'Note'"
                                                         name="note"
                                                         :readonly="readonly"/>
                                    </div>
                                </div>
                                <div class="row"
                                     v-if="form.badges_history_sorted !== undefined && form.badges_history_sorted.length > 0">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col">
                                                <label>Badges history</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col" v-for="badge in form.badges_history_sorted">
                                                <small>{{ badge.edition.name }} -> {{ badge.badge_type.name }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" v-if="advancedSearch">
                                    <div class="col">
                                        <multi-select-input :form="form"
                                                            label="Badge types"
                                                            name="badge_types"
                                                            :options="$badge_types"
                                                            valueLabel="name"
                                        />
                                    </div>
                                    <div class="col">
                                        <multi-select-input :form="form"
                                                            label="Badge years"
                                                            name="badge_years"
                                                            :options="$editions"
                                                            valueLabel="name"
                                                            :multiple="false"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="card">
                                            <div class="card-header">
                                                Latest badge {{ form.latestBadgeYear }}
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="card">
                                                            <div class="card-header">
                                                                Accreditation
                                                            </div>
                                                            <div class="card-body">
                                                                <radio-input :label="'Badge'"
                                                                             :form="form"
                                                                             name="badge_latest"
                                                                             :options="$badge_types_accreditations"
                                                                             :readonly="readonly"/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="card">
                                                            <div class="card-header">
                                                                Subscriber
                                                            </div>
                                                            <div class="card-body">
                                                                <radio-input :label="'Badge'"
                                                                             :form="form"
                                                                             name="badge_latest"
                                                                             :options="$badge_types_subscribers"
                                                                             :readonly="readonly"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col offset-4">
                                                        <checkbox-input :form="form"
                                                                        :label="'Badge collected'"
                                                                        name="badge_collected"
                                                                        :readonly="readonly"/>

                                                        <checkbox-input :form="form"
                                                                        :label="'Non paying'"
                                                                        name="non_paying"
                                                                        :readonly="readonly"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                Hospitality
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <text-input :form="form"
                                                                    type="number"
                                                                    :label="'Duration'"
                                                                    name="hospitality_duration"
                                                                    :readonly="readonly"/>
                                                    </div>
                                                    <div class="col-7">
                                                        <text-input :form="form"
                                                                    :label="'Accomodation'"
                                                                    name="hospitality_accomodation"
                                                                    :readonly="readonly"/>
                                                    </div>
                                                    <div class="col-12">
                                                        <select-input :form="form"
                                                                      :label="'Type'"
                                                                      name="hospitality_type_id"
                                                                      :options="$hospitality_types"
                                                                      :readonly="readonly"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="card">
                                            <div class="card-header">
                                                Press info
                                            </div>
                                            <div class="card-body">
                                                <checkbox-input :form="form"
                                                                :label="'Press conference'"
                                                                name="press_conference"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'Call for entries'"
                                                                name="call_for_entries"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'CS daily'"
                                                                name="cs_daily"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'CS monthly'"
                                                                name="cs_monthly"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'CS weekly'"
                                                                name="cs_weekly"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'CS invitation'"
                                                                name="cs_invitation"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'CS industry'"
                                                                name="cs_industry"
                                                                :readonly="readonly"/>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                Festival info
                                            </div>
                                            <div class="card-body">
                                                <checkbox-input :form="form"
                                                                :label="'Opening invitation'"
                                                                name="opening_invitation"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'Closing invitation'"
                                                                name="closing_invitation"
                                                                :readonly="readonly"/>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                Various info
                                            </div>
                                            <div class="card-body">
                                                <checkbox-input :form="form"
                                                                :label="'On demand'"
                                                                name="on_demand"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'Catalogue'"
                                                                name="catalogue"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'Industry meetings'"
                                                                name="industry_meetings"
                                                                :readonly="readonly"/>
                                                <checkbox-input :form="form"
                                                                :label="'Retired'"
                                                                name="retired"
                                                                :readonly="readonly"/>
                                                <checkbox-input v-if="!readonly"
                                                                :form="form"
                                                                :label="'Deceased'"
                                                                name="deceased"
                                                                :readonly="readonly"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row"
                             v-if="readonly"
                             style="padding-bottom: 10px;">
                            <div class="col-12 col-xl-6"
                                 v-if="form.related_people_cards && form.related_people_cards.length > 0">
                                <ul class="nav nav-pills">
                                    <router-link v-for="card in form.related_people_cards"
                                                 :to="'/people-card/'+card.id"
                                                 :key="card.id">
                                        <li class="nav-item nav">
                                            <a class="nav-link active people"
                                               href="#">{{ card.last_name }} {{ card.first_name }}</a>
                                        </li>
                                    </router-link>&nbsp;&nbsp;&nbsp;&nbsp;
                                </ul>
                            </div>
                            <div class="col-12 col-xl-6"
                                 v-if="form.company_cards && form.company_cards.length > 0">
                                <ul class="nav nav-pills">
                                    <router-link v-for="card in form.company_cards"
                                                 :to="'/company-card/'+card.id"
                                                 :key="card.id">
                                        <li class="nav-item">
                                            <a class="nav-link active company" href="#">{{ card.full_name }} {{
                                                    card.last_name
                                                }}</a>
                                        </li>
                                    </router-link>&nbsp;&nbsp;&nbsp;
                                </ul>
                            </div>
                        </div>

                        <div class="row"
                             v-else
                             style="padding-bottom: 10px;">
                            <div class="col-12 col-xl-6">
                                <multi-select-input :form="form"
                                                    label="Related people"
                                                    name="related_people_cards"
                                                    :searchChange="(queryString) => asyncPeopleFind(queryString)"
                                                    valueLabel="full_name"
                                />
                            </div>
                            <div class="col-12 col-xl-6">
                                <multi-select-input :form="form"
                                                    label="Related companies"
                                                    name="company_cards"
                                                    :searchChange="(queryString) => asyncCompaniesFind(queryString)"
                                                    valueLabel="full_name"
                                />
                            </div>
                        </div>

                        <div class="row" v-if="!readonly && type !== 'search'">
                            <div class="col-4">
                                <button class="btn btn-sm company" @click.prevent="newBusinessCard">Add new
                                    business
                                    card
                                </button>
                            </div>
                        </div>

                        <div class="row" v-if="form.business_cards">
                            <div class="col">
                                <div class="row">
                                    <div class="col-6" v-for="(card, index) in form.business_cards">
                                        <div class="card">
                                            <div class="card-header"
                                                 v-if="readonly && card.company_cards[0]">
                                                <router-link
                                                    :to="'/company-card/'+card.company_cards[0].id">
                                                    <a href="#" class="btn btn-sm company">{{
                                                            card.company_cards[0].full_name
                                                        }}</a>
                                                </router-link>
                                            </div>
                                            <div class="card-header"
                                                 v-if="!readonly">
                                                <div class="row">
                                                    <div class="col">
                                                        <business-card-company-multi-select-input
                                                            :form="form"
                                                            :searchChange="(queryString) => asyncCompaniesFind(queryString)"
                                                            valueLabel="full_name"
                                                            :max="1"
                                                            :nested-index="index"
                                                            name="company_cards"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <button @click.prevent="removeBusinessCard(card)"
                                                                class="btn btn-sm btn-danger">Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <nested-text-input :form="form"
                                                                           :label="'Email'"
                                                                           name="email"
                                                                           nested-key="business_cards"
                                                                           :nested-index="index"
                                                                           :readonly="readonly"
                                                        />
                                                        <nested-text-input :form="form"
                                                                           :label="'Phone'"
                                                                           name="phone"
                                                                           nested-key="business_cards"
                                                                           :nested-index="index"
                                                                           :readonly="readonly"
                                                        />
                                                        <nested-text-input :form="form"
                                                                           :label="'Description'"
                                                                           name="description"
                                                                           nested-key="business_cards"
                                                                           :nested-index="index"
                                                                           :readonly="readonly"
                                                        />
                                                        <nested-text-input :form="form"
                                                                           :label="'Street'"
                                                                           name="street"
                                                                           nested-key="business_cards"
                                                                           :nested-index="index"
                                                                           :readonly="readonly"
                                                        />
                                                        <nested-text-input :form="form"
                                                                           :label="'City'"
                                                                           name="city"
                                                                           nested-key="business_cards"
                                                                           :nested-index="index"
                                                                           :readonly="readonly"
                                                        />
                                                        <nested-text-input :form="form"
                                                                           :label="'Zip code'"
                                                                           name="zip_code"
                                                                           nested-key="business_cards"
                                                                           :nested-index="index"
                                                                           :readonly="readonly"
                                                        />
                                                        <nested-text-input :form="form"
                                                                           :label="'State'"
                                                                           name="state"
                                                                           nested-key="business_cards"
                                                                           :nested-index="index"
                                                                           :readonly="readonly"
                                                        />
                                                        <nested-text-input :form="form"
                                                                           :label="'Country'"
                                                                           name="country"
                                                                           nested-key="business_cards"
                                                                           :nested-index="index"
                                                                           :readonly="readonly"
                                                        />
                                                        <nested-text-input :form="form"
                                                                           :label="'Note'"
                                                                           name="note"
                                                                           nested-key="business_cards"
                                                                           :nested-index="index"
                                                                           :readonly="readonly"
                                                        />
                                                        <nested-text-input :form="form"
                                                                           :label="'Job title'"
                                                                           name="job_title"
                                                                           nested-key="business_cards"
                                                                           :nested-index="index"
                                                                           :readonly="readonly"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectInput from "../components/SelectInput"
import TextAreaInput from "../components/TextAreaInput"
import MultiSelectInput from "../components/MultiSelectInput"
import TextInput from "../components/TextInput"
import {HasError} from "vform/src/components/bootstrap4"
import Multiselect from "vue-multiselect"
import sharedState from "../sharedState"
import CheckboxInput from "../components/CheckboxInput"
import RadioInput from "../components/RadioInput"
import NestedTextInput from "./NestedTextInput"
import companyCards from "../services/api/companyCards"
import peopleCards from "../services/api/peopleCards"
import BusinessCardCompanyMultiSelectInput from "./BusinessCardCompanyMultiSelectInput"
import AndOrInput from "./AndOrInput.vue"

export default {
    components: {
        AndOrInput,
        BusinessCardCompanyMultiSelectInput,
        NestedTextInput,
        SelectInput,
        TextAreaInput,
        MultiSelectInput,
        TextInput,
        HasError,
        Multiselect,
        CheckboxInput,
        RadioInput
    },
    name: "PeopleCardForm",
    props: {
        form: Object,
        readonly: {type: Boolean, default: false},
        type: {type: String, default: 'show'},
        advancedSearch: {type: Boolean, default: false}
    },
    data() {
        return {
            sharedState: sharedState,
        }
    },
    methods: {
        async asyncCompaniesFind(queryString) {
            return await companyCards.index(queryString)
        },
        async asyncPeopleFind(queryString) {
            return await peopleCards.index(queryString)
        },
        newBusinessCard() {
            this.form.business_cards.unshift({company_cards: null})
        },
        removeBusinessCard(card) {
            let index = this.form.business_cards.findIndex(item => item.id === card.id)
            this.form.business_cards.splice(index, 1)
        }
    }
}
</script>

<style scoped>

</style>
